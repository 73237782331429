// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentFooter {
    height: 320px;
    width: 100%;
    background-color: #003953;
    border: solid;
    padding: 60px 80px;
    color: white;
    border: none;

    @media (max-width: 600px) {
        height: -moz-fit-content;
        height: fit-content;
        padding: 30px 30px;
      }
}
.contentFooter p {
    font-size: 12px;
    width: 70%;
    margin-top: 20px;
}
.wrapFooterTabs {
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}
.footerTab {
    font-size: 15px;
    margin: 15px 30px 5px 0px;

    @media (max-width: 600px) {
        margin: 20px 0px 5px 0px;
    }
}
.footerIcons {
    margin-top: 30px;
}
.footerIcons i {
    margin: 0px 5px;
}
.copyright {
    background-color: #001C2A;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/VolunteerFooter/VolunteerFooter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,YAAY;;IAEZ;QACI,wBAAmB;QAAnB,mBAAmB;QACnB,kBAAkB;MACpB;AACN;AACA;IACI,eAAe;IACf,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,aAAa;;IAEb;QACI,sBAAsB;IAC1B;AACJ;AACA;IACI,eAAe;IACf,yBAAyB;;IAEzB;QACI,wBAAwB;IAC5B;AACJ;AACA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".contentFooter {\n    height: 320px;\n    width: 100%;\n    background-color: #003953;\n    border: solid;\n    padding: 60px 80px;\n    color: white;\n    border: none;\n\n    @media (max-width: 600px) {\n        height: fit-content;\n        padding: 30px 30px;\n      }\n}\n.contentFooter p {\n    font-size: 12px;\n    width: 70%;\n    margin-top: 20px;\n}\n.wrapFooterTabs {\n    display: flex;\n\n    @media (max-width: 600px) {\n        flex-direction: column;\n    }\n}\n.footerTab {\n    font-size: 15px;\n    margin: 15px 30px 5px 0px;\n\n    @media (max-width: 600px) {\n        margin: 20px 0px 5px 0px;\n    }\n}\n.footerIcons {\n    margin-top: 30px;\n}\n.footerIcons i {\n    margin: 0px 5px;\n}\n.copyright {\n    background-color: #001C2A;\n    height: 50px;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

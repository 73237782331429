// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapSettings {
    display: flex;
    float: right;
    background-color: #fafafa;
}
.settings {
    width: 100%;
    margin: 15px;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/Settings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".wrapSettings {\n    display: flex;\n    float: right;\n    background-color: #fafafa;\n}\n.settings {\n    width: 100%;\n    margin: 15px;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successPage{
    display: flex;
    flex-direction: column;
    
}

.successImg {
    display: flex;
    flex-direction: column;
    background-color: #F5FBF3;
    padding: 2% 5%;
    align-items: center;
}

.successComment1{
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #277C3B;
    margin: 2% 0% 1% 0%;

}

.successComment2{
    font-family: Inter;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #277C3B;

}
.successMsg {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 5%;
}

.msgBody{
    width: 60%;
    

}
.msg{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #5D5B5B;
}
.homeButton2 {
    padding: 8px 30px;
    color: white;
    background-color:#0080BC ;
    cursor: pointer;
    border-radius: 5px;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/RegFormSuccess/RegFormSuccess.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;;AAEvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;;AAElB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,UAAU;;;AAGd;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".successPage{\n    display: flex;\n    flex-direction: column;\n    \n}\n\n.successImg {\n    display: flex;\n    flex-direction: column;\n    background-color: #F5FBF3;\n    padding: 2% 5%;\n    align-items: center;\n}\n\n.successComment1{\n    font-family: Raleway;\n    font-size: 32px;\n    font-weight: 600;\n    line-height: 38px;\n    letter-spacing: 0em;\n    text-align: center;\n    color: #277C3B;\n    margin: 2% 0% 1% 0%;\n\n}\n\n.successComment2{\n    font-family: Inter;\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 24px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #277C3B;\n\n}\n.successMsg {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 2% 5%;\n}\n\n.msgBody{\n    width: 60%;\n    \n\n}\n.msg{\n    font-family: Inter;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #5D5B5B;\n}\n.homeButton2 {\n    padding: 8px 30px;\n    color: white;\n    background-color:#0080BC ;\n    cursor: pointer;\n    border-radius: 5px;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

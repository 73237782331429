// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapDashboard {
    height: 100%;
    background-color: #FAFAFA;
    padding: 10px 20px 10px 10px;
}
.dashboard {
    height: 100%;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,4BAA4B;AAChC;AACA;IACI,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".wrapDashboard {\n    height: 100%;\n    background-color: #FAFAFA;\n    padding: 10px 20px 10px 10px;\n}\n.dashboard {\n    height: 100%;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapHelp {
    display: flex;
    float: right;
    height: 100%;
    background-color: #fafafa;
}
.help {
    width: 100%;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Help/Help.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".wrapHelp {\n    display: flex;\n    float: right;\n    height: 100%;\n    background-color: #fafafa;\n}\n.help {\n    width: 100%;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import './Dashboard.css'

function Dashboard() {
  return (
    <div className="wrapDashboard"> 
      <div className="dashboard"> 
        Dashboard
      </div> 
    </div>
  )
}

export default Dashboard
import React from 'react'
import './Accounts.css'

function Accounts() {
  return (
    <div className="wrapAccounts"> 
      <div className="accounts">
        Accounts
      </div>
    </div>
  )
}

export default Accounts
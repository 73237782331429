// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPage {
  height: 100vh;
}
.wrapSideNav {
  border: solid;
  border-color: #ebebeb;
  z-index: 2;
}

.wrapHeader {
  border-top: solid;
  border-bottom: solid;
  height: 60px;
  border-color: #ebebeb;
  align-items: center;
  justify-content: center;
  padding: 9px 20px 9px 12px;
}
.wrapContent {
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-bottom: solid #ebebeb;
}
`, "",{"version":3,"sources":["webpack://./src/containers/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;EACvB,0BAA0B;AAC5B;AACA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,4BAA4B;AAC9B","sourcesContent":[".mainPage {\n  height: 100vh;\n}\n.wrapSideNav {\n  border: solid;\n  border-color: #ebebeb;\n  z-index: 2;\n}\n\n.wrapHeader {\n  border-top: solid;\n  border-bottom: solid;\n  height: 60px;\n  border-color: #ebebeb;\n  align-items: center;\n  justify-content: center;\n  padding: 9px 20px 9px 12px;\n}\n.wrapContent {\n  height: calc(100% - 60px);\n  background-color: #ffffff;\n  border-bottom: solid #ebebeb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

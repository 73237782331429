import React from 'react'
import './Help.css'

function Help() {
  return (
    <div className="wrapHelp"> 
      <div className="help">
        Help
      </div>
    </div>
  )
}

export default Help
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapDayTime {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.label-days {
    width: 120px;
    height: 30px;
}
.container-time {
    display: flex;
    gap: 30px;

    @media (max-width: 600px) {
        display: block;
    }
}
.label-time {
    display: flex;
    flex-direction: column;
}
.label-time span {
    font-size: 14px;
    color: #1F1F1F;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/RaiseNeed/MonoSelect.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;AAC/B;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,aAAa;IACb,SAAS;;IAET;QACI,cAAc;IAClB;AACJ;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".wrapDayTime {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}\n.label-days {\n    width: 120px;\n    height: 30px;\n}\n.container-time {\n    display: flex;\n    gap: 30px;\n\n    @media (max-width: 600px) {\n        display: block;\n    }\n}\n.label-time {\n    display: flex;\n    flex-direction: column;\n}\n.label-time span {\n    font-size: 14px;\n    color: #1F1F1F;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import './Settings.css'

function Settings() {
  return (
    <div className="wrapSettings"> 
      <div className="settings">
        Settings
      </div>
    </div>
  )
}

export default Settings
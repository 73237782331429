// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-multiselect {
  display: flex;
  flex-direction: column;
}
.wrap-add-button {
  display: flex;
  gap: 10px;
}
.wrap-add-button span{
  vertical-align: middle;
}
.container-daysTime {
  display: flex;
  gap: 50px;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    display: block;
}
}
.wrap-remove-button {
  border: none;
}
.day-item {
  border: none;
}
.time-item {
  border: none;
}
.days-label {
  height: 30px;
  width: 120px;
  padding: 28px 0px;
}
.add-button {
  height: 30px;
  border-radius: 50%;
  color: green;
  border: none;
  background-color: inherit;
  margin-left: 10px;
  font-weight: 800;
  font-size: 32px;
  margin-top: -14px;
}
.remove-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: red;
  border: none;
  background-color: inherit;
  margin-left: 10px;
  font-weight: 800;
  font-size: 24px;
  margin-top: -8px;
}

/* .custom-time-picker {
  width: 120px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/RaiseNeed/MultiSelect.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;;EAElB;IACE,cAAc;AAClB;AACA;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;GAEG","sourcesContent":[".container-multiselect {\n  display: flex;\n  flex-direction: column;\n}\n.wrap-add-button {\n  display: flex;\n  gap: 10px;\n}\n.wrap-add-button span{\n  vertical-align: middle;\n}\n.container-daysTime {\n  display: flex;\n  gap: 50px;\n  margin-bottom: 5px;\n\n  @media (max-width: 600px) {\n    display: block;\n}\n}\n.wrap-remove-button {\n  border: none;\n}\n.day-item {\n  border: none;\n}\n.time-item {\n  border: none;\n}\n.days-label {\n  height: 30px;\n  width: 120px;\n  padding: 28px 0px;\n}\n.add-button {\n  height: 30px;\n  border-radius: 50%;\n  color: green;\n  border: none;\n  background-color: inherit;\n  margin-left: 10px;\n  font-weight: 800;\n  font-size: 32px;\n  margin-top: -14px;\n}\n.remove-button {\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n  color: red;\n  border: none;\n  background-color: inherit;\n  margin-left: 10px;\n  font-weight: 800;\n  font-size: 24px;\n  margin-top: -8px;\n}\n\n/* .custom-time-picker {\n  width: 120px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPage {
    height: 100vh;
}
.wrapSideNav {
    border: solid;
    height: 100%;
    border-color: #EBEBEB;
    z-index: 2;
}
.wrapDisplay {
    height: 100%;
}
.wrapHeader {
    border-top:solid;
    border-bottom:solid;
    height: 60px;
    border-color: #EBEBEB;
    align-items: center;
    justify-content: center;
    padding: 9px 20px 9px 12px;
}
.wrapContent {
    height: calc( 100% - 60px);
    background-color: #FBFBFB;
    border-bottom: solid #EBEBEB;
}`, "",{"version":3,"sources":["webpack://./src/containers/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,UAAU;AACd;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,0BAA0B;AAC9B;AACA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,4BAA4B;AAChC","sourcesContent":[".mainPage {\n    height: 100vh;\n}\n.wrapSideNav {\n    border: solid;\n    height: 100%;\n    border-color: #EBEBEB;\n    z-index: 2;\n}\n.wrapDisplay {\n    height: 100%;\n}\n.wrapHeader {\n    border-top:solid;\n    border-bottom:solid;\n    height: 60px;\n    border-color: #EBEBEB;\n    align-items: center;\n    justify-content: center;\n    padding: 9px 20px 9px 12px;\n}\n.wrapContent {\n    height: calc( 100% - 60px);\n    background-color: #FBFBFB;\n    border-bottom: solid #EBEBEB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
